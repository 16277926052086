import { Master } from 'app/models/master';
import { Option } from 'app/models/common/option';
import { MastersQueryParams } from 'app/models/master/masters-query-params';
import { HttpService } from '../http.service';
import { CreateMasterRequest } from './dtos/create-master.request';
import { UpdateMasterRequest } from './dtos/update-master.request';
import { TableDto } from '../models/table.dto';

class MasterServiceImpl {
  private static apiEndpoint = 'api/masters';

  getMaster(id: string): Promise<Master> {
    return HttpService.get<Master>(`${MasterServiceImpl.apiEndpoint}/${id}/`).then(
      response => response.data
    );
  }

  createMaster(request: CreateMasterRequest): Promise<Master> {
    return HttpService.post<Master>(MasterServiceImpl.apiEndpoint, request).then(
      response => response.data
    );
  }

  updateMaster(request: UpdateMasterRequest): Promise<Master> {
    return HttpService.put<Master>(`${MasterServiceImpl.apiEndpoint}/${request.id}`, request).then(
      response => response.data
    );
  }

  deleteMaster(id: string): Promise<void> {
    return HttpService.delete<void>(`${MasterServiceImpl.apiEndpoint}/${id}`).then(
      response => response.data
    );
  }

  getMasters(params: MastersQueryParams): Promise<TableDto<Master>> {
    const { per_page, page, ...filters } = params;

    const queryParams = {
      per_page,
      page,
      filters,
    };

    return HttpService.get<TableDto<Master>>(`${MasterServiceImpl.apiEndpoint}/filtered-paged/`, {
      params: queryParams,
    }).then(response => response.data);
  }

  getMastersPositions(): Promise<Option<number>[]> {
    return HttpService.get<Option<number>[]>(
      `${MasterServiceImpl.apiEndpoint}/masters-positions`
    ).then(response => response.data);
  }
}

export const MasterService = new MasterServiceImpl();
