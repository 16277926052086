import { useOnClickOutside } from 'app/helpers/click-outside/click-outside.hook';
import { OptionMenu } from 'app/models/common/option';
import React, { createRef, ReactNode, RefObject, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../themes/colors';
import { ColumnFlexWithPadding } from '../../../typography/flex';

interface MenuProps {
  options: OptionMenu[];
  className?: string;
  children: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const Menu = (props: MenuProps) => {
  const { className, children, fullWidth, options, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  const componentRef: RefObject<HTMLDivElement> = createRef();
  const hideMenu = () => {
    setIsOpen(false);
  };

  const clickOutsideEventHandler = ($event: MouseEvent | TouchEvent) => {
    $event.stopPropagation();
    if (isOpen && !componentRef.current!.contains($event.target as HTMLElement)) {
      hideMenu();
    }
  };

  const onOptionClick = (e, option: OptionMenu) => {
    e.stopPropagation();
    option.callback(e);
    hideMenu();
  };

  const onClickAnchor = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  useOnClickOutside(componentRef, $event => clickOutsideEventHandler($event), isOpen);

  return (
    <MenuContainer className={className} ref={componentRef}>
      <div onClick={e => onClickAnchor(e)}>{children}</div>
      <MenuComponent visible={isOpen} fullWidth={fullWidth}>
        <MenuOptionsContainer spacing="10px">
          {options?.map(option => (
            <MenuOption key={option.label} onClick={e => onOptionClick(e, option)}>
              {option.label}
            </MenuOption>
          ))}
        </MenuOptionsContainer>
      </MenuComponent>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: relative;

  input:read-only:not(:disabled) {
    cursor: pointer;
  }
`;

const MenuComponent = styled.div<{ visible: boolean; fullWidth?: boolean }>`
  ${props => (props.fullWidth ? 'width:100%' : '')};
  ${props => !props.visible && 'display:none'};
  background: ${Colors.MainBackground};
  border: 1px solid ${Colors.Grey600};
  box-sizing: border-box;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 0.375rem;
  margin-top: 5px;
  position: absolute;
  right: 0;
  z-index: 2;
  max-height: 250px;
  height: max-content;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${Colors.Blue600};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const MenuOptionsContainer = styled(ColumnFlexWithPadding)`
  padding: 15px 20px 10px;
`;

const MenuOption = styled.option`
  color: ${Colors.Grey900};
  background: ${Colors.MainBackground};
  display: flex;
  white-space: pre;
  cursor: pointer;
  font-size: 14px;

  :hover {
    color: ${Colors.Blue700};
  }
`;
