import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { FormikHelpers, useFormik } from 'formik';
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { selectSuppliersOptions } from '../../../../../../../store/suppliers/suppliers.selectors';
import { IncidentId, IncidentStatus } from '../../../../../../models/incident/incident';
import { IncidentFormInitialValues, incidentsOptionsTypes } from './constants';
import { ConsumerId } from '../../../../../../models/consumer';
import { ColumnFlexWithPadding, FlexWithSpacing } from '../../../../../../typography/flex';
import { CreateIncident, EditIncident } from '../../../../../../../store/incidents/incidents.actions';
import { validationScheme } from './validation';
import { DateContainer, IncidentDatePicker, IncidentSelect, StyledFormField, SubmitButton } from '../../styled';
import { AddressesList, addressFiasIdField } from '../../../../../Mailings/components/AddressesList';
import { environment } from '../../../../../../environment';
import { AddressesError } from '../../../../../Mailings/components/MailingDialog/styled';
import { LoadMasters } from '../../../../../../../store/masters/masters.actions';
import { selectMastersOptions } from '../../../../../../../store/masters/masters.selectors';
import { Input } from '../../../../../../components/shared';
import { selectAppealKindOptions } from '../../../../../../../store/appeals/appeals.selectors';
import { selectSelectedIncident } from '../../../../../../../store/incidents/incidents.selectors';
import { GetProfilesBySupplier } from '../../../../../../../store/profile/profile.actions';
import { selectProfilesOptions } from '../../../../../../../store/profile/profile.selectors';

type FormProps = {
  incidentId?: IncidentId;
  getToWork: boolean;
};

export const Form = React.memo<FormProps>(props => {
  const dispatch = useDispatch();
  const suppliersOptions = useSelector(selectSuppliersOptions, shallowEqual);
  const appealKindsOptions = useSelector(selectAppealKindOptions, shallowEqual);
  const mastersOptions = useSelector(selectMastersOptions, shallowEqual);
  const responsibleOptions = useSelector(selectProfilesOptions, shallowEqual);
  const { incident_type, appeal_kind, text, supplier, responsible, planned_end_date, master, addresses } =
    useSelector(selectSelectedIncident, shallowEqual) ?? {};

  const [isBlur, setIsBlur] = React.useState(false);

  const initialValues: IncidentFormInitialValues = React.useMemo(() => {
    if (!props.incidentId) {
      return {
        validateExtraFields: false,
        incidentType: 'accident',
        appealKindId: appealKindsOptions?.[0]?.value ?? '',
        text: '',
        supplierId: '',
        responsibleId: '',
        masterId: '',
        date: '',
        time: '',
        addresses: [],
      };
    }

    const date = planned_end_date;

    return {
      validateExtraFields: props.getToWork,
      incidentType: incident_type ?? 'accident',
      appealKindId: appeal_kind ?? '',
      text: text ?? '',
      supplierId: supplier?.id ?? '',
      responsibleId: responsible?.id ?? '',
      masterId: master?.id ?? '',
      date: date ? format(new Date(date), 'yyyy-MM-dd') : '',
      time: date ? format(new Date(date), 'HH:mm') : '',
      addresses: addresses || [],
    };
  }, [
    addresses,
    appealKindsOptions,
    appeal_kind,
    incident_type,
    master?.id,
    planned_end_date,
    props.incidentId,
    props.getToWork,
    responsible?.id,
    supplier?.id,
    text,
  ]);

  const onSubmitHandler = React.useCallback(
    (values: IncidentFormInitialValues, formikHelpers: FormikHelpers<IncidentFormInitialValues>) => {
      formikHelpers.setErrors({});
      const date = parse(`${values.date} ${values.time}`, 'yyyy-MM-dd HH:mm', new Date());

      const data = {
        appeal_kind: values.appealKindId,
        incident_type: values.incidentType,
        text: values.text,
        master: values.masterId || null,
        supplier: values.supplierId,
        responsible: values.responsibleId as ConsumerId,
        planned_end_date: values.date && values.time ? date.toISOString() : null,
        addresses: values.addresses,
      };

      if (props?.incidentId) {
        dispatch(
          EditIncident.init({
            ...data,
            supplier: undefined,
            id: props.incidentId,
            status: props.getToWork ? IncidentStatus.InProgress : undefined,
          })
        );
      } else {
        dispatch(CreateIncident.init(data));
      }

      formikHelpers.setSubmitting(false);
    },
    [dispatch, props.getToWork, props.incidentId]
  );

  const formik = useFormik<IncidentFormInitialValues>({
    initialValues,
    validationSchema: validationScheme,
    onSubmit: onSubmitHandler,
    validateOnBlur: true,
  });

  const onSelectChangeHandler = React.useCallback(
    (value: string | undefined | number, field: string) => {
      if (!value) return;

      formik.setFieldValue(field, value);
    },
    [formik]
  );

  const onAddressChangeHandler = React.useCallback(
    (event: DaDataSuggestion<DaDataAddress> | undefined) => {
      if (!event) return;

      const level = event?.data.fias_level;
      const newAddress = { address: event?.value, [addressFiasIdField[level]]: event?.data[addressFiasIdField[level]] };

      if (formik.values.addresses.some(address => address[addressFiasIdField[level]] === newAddress[addressFiasIdField[level]])) {
        return;
      }
      formik.setFieldValue('addresses', [...formik.values.addresses, newAddress]);
    },
    [formik]
  );

  const removeAddressHandler = React.useCallback(
    (index: number) => {
      const newAddresses = formik.values.addresses.filter((_, i) => i !== index);
      formik.setFieldValue('addresses', newAddresses);
    },
    [formik]
  );

  React.useEffect(() => {
    if (!formik.values.supplierId) return;

    dispatch(
      LoadMasters.init({
        per_page: 1000,
        managementCompany__in: [formik.values.supplierId],
      })
    );

    dispatch(GetProfilesBySupplier.init({ supplierId: formik.values.supplierId }));
  }, [dispatch, formik.values.supplierId]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ColumnFlexWithPadding spacing="5px">
        <FlexWithSpacing spacing="20px">
          <IncidentSelect
            label="Тип *"
            id="incidentType"
            value={formik.values.incidentType}
            icon="chevron-down"
            name="incidentType"
            options={incidentsOptionsTypes}
            onChange={value => onSelectChangeHandler(value, 'incidentType')}
            error={formik.errors.incidentType}
          />

          <IncidentSelect
            label="Услуга *"
            icon="chevron-down"
            name="appealKindId"
            id="appealKindId"
            value={formik.values.appealKindId}
            options={appealKindsOptions}
            onChange={value => onSelectChangeHandler(value, 'appealKindId')}
            error={formik.errors.appealKindId}
          />
        </FlexWithSpacing>
        <StyledFormField placeholder="Адрес *">
          <AddressSuggestions
            autoload
            selectOnBlur
            delay={500}
            minChars={3}
            token={environment.dadataAPIKey}
            inputProps={{
              placeholder: 'Город, район, улица или конкретный адрес',
              onBlur: () => setIsBlur(true),
              autoComplete: 'off',
            }}
            onChange={event => onAddressChangeHandler(event)}
          />

          {!!formik.values.addresses.length && <AddressesList addresses={formik.values.addresses} onDeleteClick={removeAddressHandler} />}

          {!formik.values.addresses.length && isBlur && <AddressesError>Выберите адрес</AddressesError>}
        </StyledFormField>
        <StyledFormField placeholder="Описание проблемы *">
          <Input
            name="text"
            id="text"
            onChange={formik.handleChange}
            value={formik.values.text}
            error={formik.errors.text}
            noError={!formik.errors.text}
            placeholder="Дополнительная информация об инцеденте"
          />
        </StyledFormField>
        <FlexWithSpacing spacing="20px">
          <IncidentSelect
            label="Поставщик *"
            id="supplierId"
            placeholder="Выберите из списка"
            value={formik.values.supplierId}
            name="supplierId"
            options={suppliersOptions}
            onChange={value => onSelectChangeHandler(value, 'supplierId')}
            error={formik.errors.supplierId}
            disabled={!!props.incidentId}
            search
          />

          <IncidentSelect
            label="Ответственный *"
            id="responsibleId"
            placeholder="Выберите из списка"
            value={formik.values.responsibleId}
            icon="chevron-down"
            name="responsibleId"
            options={responsibleOptions}
            onChange={value => onSelectChangeHandler(value, 'responsibleId')}
            disabled={!formik.values.supplierId}
            error={formik.errors.responsibleId}
          />
        </FlexWithSpacing>
        <FlexWithSpacing spacing="20px">
          <IncidentSelect
            label={props.getToWork ? 'Мастер*' : 'Мастер'}
            id="masterId"
            placeholder="Выберите из списка"
            value={formik.values.masterId}
            icon="chevron-down"
            name="masterId"
            options={mastersOptions}
            onChange={value => onSelectChangeHandler(value, 'masterId')}
            disabled={!formik.values.supplierId}
            error={formik.errors.masterId}
          />

          <DateContainer spacing="10px">
            <StyledFormField placeholder={props.getToWork ? 'Дата окончания*' : 'Дата окончания'}>
              <IncidentDatePicker
                onChange={formik.handleChange}
                id="date"
                name="date"
                type="date"
                value={formik.values.date}
                error={formik.errors.date}
                min={format(new Date(), 'yyyy-MM-dd')}
              />
            </StyledFormField>

            <StyledFormField placeholder={props.getToWork ? 'Время окончания*' : 'Время окончания'}>
              <IncidentDatePicker
                onChange={formik.handleChange}
                id="time"
                type="time"
                name="time"
                value={formik.values.time}
                error={formik.errors.time}
              />
            </StyledFormField>
          </DateContainer>
        </FlexWithSpacing>

        <SubmitButton
          disabled={
            !formik.isValid ||
            formik.isSubmitting ||
            !formik.values.addresses.length ||
            (props.getToWork && (!formik.values.masterId || !formik.values.date))
          }
          mod="primary"
          type="submit"
        >
          {props.getToWork ? 'Взять в работу' : 'Сохранить'}
        </SubmitButton>
      </ColumnFlexWithPadding>
    </form>
  );
});
