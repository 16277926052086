import { createSlice } from '@reduxjs/toolkit';
import { getChosenFilter, resetSomeFilters } from 'app/helpers/filters/get-chosen-filter';
import { Call } from 'app/models/call';
import { replaceOnce } from 'app/helpers/arrays';
import { ChosenFilters } from 'app/models/common/chosen-filters';
import { getNewFilterValue } from 'app/helpers/filters/get-filter-new-value';
import * as CallsActions from './calls.actions';
import { CALLS_STORE_NAME } from './calls.actions';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';

export interface CallsState extends EntityState {
  calls: Call[];
  callsTotalCount: number;
  chosenFilters: ChosenFilters;
  newCall: Call | null;
  error?: any;
}

export const callsInitialState: CallsState = {
  calls: [],
  callsTotalCount: 0,
  chosenFilters: {},
  newCall: null,
  ...initialEntityState,
};

const callsSlice = createSlice({
  name: CALLS_STORE_NAME,
  initialState: callsInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(CallsActions.LoadCalls.init, state => {
        state.loading = true;
      })
      .addCase(CallsActions.LoadCalls.success, (state, { payload }) => {
        state.loading = false;
        if (!payload.calls) return;
        state.calls = payload.page === 1 ? payload.calls : state.calls.concat(payload.calls);
        state.callsTotalCount = payload.callsTotalCount;
      })
      .addCase(CallsActions.LoadCalls.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(CallsActions.LoadCallsCreatedGte.success, (state, { payload }) => {
        state.loading = false;
        state.calls = [...payload.calls, ...state.calls];
        state.callsTotalCount = payload.callsTotalCount;
      })
      .addCase(CallsActions.LoadCallsCreatedGte.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(CallsActions.setChosenFilter, (state, { payload }) => {
        state.chosenFilters = getChosenFilter([], payload.filters);
      })
      .addCase(CallsActions.changeChosenFilter, (state, { payload }) => {
        state.chosenFilters[payload.key] = getNewFilterValue(payload.value);
      })
      .addCase(CallsActions.UpdateCall.init, state => {
        state.updating = true;
      })
      .addCase(CallsActions.UpdateCall.success, (state, { payload }) => {
        state.updating = false;
        state.calls = replaceOnce(state.calls, ({ id }) => id === payload.id, payload); // обновляем старый звонок в сторе
      })
      .addCase(CallsActions.UpdateCall.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(CallsActions.CreateCall.init, state => {
        state.creating = true;
        state.newCall = null;
      })
      .addCase(CallsActions.CreateCall.success, (state, { payload }) => {
        state.creating = false;
        state.newCall = payload;
      })
      .addCase(CallsActions.CreateCall.failure, (state, { payload }) => {
        state.creating = false;
        state.error = payload;
        state.newCall = null;
      })

      .addCase(CallsActions.resetSomeFilters, (state, { payload }) => {
        state.chosenFilters = resetSomeFilters(state.chosenFilters, payload);
      }),
});

export const callsReducer = callsSlice.reducer;
