import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'types/AppState.type';
import createCachedSelector from 're-reselect';
import { CallsState } from './calls.reducer';
import { CallId } from '../../app/models/call';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';

const selectCallsState: (state: AppState) => CallsState = state => state.callsState;

export const selectCalls = createSelector(selectCallsState, state => state.calls);

const callByIdSelector = (() => {
  const selector = createCachedSelector([selectCalls, (_S: AppState, callId: CallId | undefined) => callId], (calls, callId) => {
    if (!callId) return;

    return calls?.find(call => call.id === callId);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState, callId: CallId | undefined) => selector(state, callId);
})();

export const selectCallById = (callId: CallId | undefined) => (state: AppState) => callByIdSelector(state, callId);

export const selectNewCall = (state: AppState) => state.callsState.newCall;

export const selectChosenFilters = createSelector(selectCallsState, state => state.chosenFilters);
export const selectCallsTotalCount = createSelector(selectCallsState, state => state.callsTotalCount);

export const selectLoading = createSelector(selectCallsState, state => state.loading);
export const selectError = createSelector(selectCallsState, state => state.error);

export const selectIsFiltered = createSelector(selectCallsState, state => {
  // eslint-disable-next-line
  const { comment__icontains, ...filters } = state.chosenFilters;
  return Object.values(filters).filter(Boolean).length > 0;
});

const lastCallDateSelector = (() => {
  const selector = createCachedSelector([selectCalls], calls => {
    if (calls?.length === 0) return null; // Обработка случая, когда нет звонков

    return calls[0].createdAt;
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectLastCallDate = (state: AppState) => lastCallDateSelector(state);
