import * as React from 'react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { createRef, RefObject, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GlobalStyle } from 'styles/global-styles';
import { WebPhoneHandler } from 'app/components/WebPhone/WebPhoneHandler';
import { SuppliersActions } from 'store/suppliers';
import * as ConfigurationActions from 'store/configuration/configuration.actions';
import { LoadProfile } from 'store/profile/profile.actions';
import { AppealsActions } from 'store/appeals';
import { ModalLayer } from './services/modal-service/modal-layer.service';
import { dialogElementRefResolve } from './helpers/modal-ref/modal-ref';
import { Modal } from './services/modal-service/modals/modal';
import { SideModal } from './services/modal-service/modals/side-modal';
import { PagesRoutes, RoutePath } from './constants/route-path';
import history from './history';
import { LoginPage } from './pages/Login';
import { NotFoundPage } from './components/NotFoundPage';
import { CustomRouter, Toolbar } from './components/shared';
import { GuardedRoute } from './components/GuardedRoute';
import { ConsumersPage } from './pages/Consumers/Loadable';
import { AppealsPage } from './pages/Appeals/Loadable';
import { CallsPage } from './pages/Calls/Loadable';
import { ConsumerPage } from './pages/Consumer/Loadable';
import { ConsumerEditPage } from './pages/ConsumerEdit/Loadable';
import { MastersPage } from './pages/Masters/Loadable';
import { AppealPage } from './pages/Appeal';
import { EditAppealPage } from './pages/EditAppeal/Loadable';
import { PushNotifications } from './components/PushNotifications';
import { ProfilePage } from './pages/Profile/Loadable';
import { MailingsPage } from './pages/Mailings/Loadable';
import { ErrorBoundary } from './components/ErrorBoundary';
import { SuppliersPage } from './pages/Suppliers';
import { SupplierPage } from './pages/Supplier';
import { StatsPage } from './pages/Stats';
import { Incidents } from './pages/Incidents/Loadable';
import { IncidentPage } from './pages/Incident';
import { LoadServices } from '../store/services/services.actions';
import { SpeechNotificationsPage } from './pages/SpeechNotifications/Loadable';
import { CreateAppealPage } from './pages/CreateAppeal/Loadable';

export function App() {
  const { i18n } = useTranslation();

  const modalRef: RefObject<any> = createRef();

  const [isUserInteracted, setIsUserInteracted] = React.useState(false);

  const handleUserInteraction = React.useCallback(() => {
    setIsUserInteracted(true);
    document.removeEventListener('click', handleUserInteraction);
    document.removeEventListener('keydown', handleUserInteraction);
  }, []);

  React.useEffect(() => {
    dialogElementRefResolve(modalRef.current);
  }, [modalRef]);

  React.useEffect(() => {
    // Добавляем слушатели событий для отслеживания взаимодействия пользователя
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('keydown', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, [handleUserInteraction]);

  return (
    <CustomRouter history={history}>
      <Helmet titleTemplate="%s - Коммуналка" defaultTitle="Коммуналка" htmlAttributes={{ lang: i18n.language }} />

      <Routes>
        <Route path="*" element={<GuardedRoutes isUserInteracted={isUserInteracted} />} />
        <Route path={RoutePath.Login} element={<LoginPage />} />
      </Routes>

      <div ref={modalRef} />
      <ModalLayer />
      <Modal />
      <SideModal />

      <GlobalStyle />
    </CustomRouter>
  );
}

const GuardedRoutes = (props: { isUserInteracted: boolean }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // TODO: uncomment after notifications add
  // useFirebase();

  useEffect(() => {
    dispatch(LoadProfile.init());
    dispatch(AppealsActions.GetAppealDictionaries());
    dispatch(ConfigurationActions.GetTablesConfiguration.init());
    dispatch(ConfigurationActions.GetSettings.init());

    // TODO: uncomment after notifications add
    // dispatch(NotificationsActions.GetNotifications.init());
    dispatch(SuppliersActions.GetSuppliers.init());
    dispatch(LoadServices.init());
  }, [dispatch]);

  return (
    <>
      <Toolbar />
      <WebPhoneHandler isUserInteracted={props.isUserInteracted} />
      <PushNotifications />

      <ErrorBoundary key={location.pathname}>
        <GuardedRoute>
          <Routes>
            <Route path={PagesRoutes.PAGES.APPEAL} element={<AppealPage />} />
            <Route path={PagesRoutes.PAGES.APPEALS} element={<AppealsPage />} />
            <Route path={PagesRoutes.PAGES.APPEAL_NEW} element={<CreateAppealPage className="editAppeal" />} />
            <Route path={PagesRoutes.PAGES.APPEAL_EDIT} element={<EditAppealPage className="editAppeal" />} />

            <Route path={PagesRoutes.PAGES.PROFILE} element={<ProfilePage />} />
            <Route path={PagesRoutes.PAGES.CALLS} element={<CallsPage />} />
            <Route path={PagesRoutes.PAGES.MASTERS} element={<MastersPage />} />
            <Route path={PagesRoutes.PAGES.MAILINGS} element={<MailingsPage />} />

            <Route path={PagesRoutes.PAGES.CONSUMERS} element={<ConsumersPage />} />
            <Route path={PagesRoutes.PAGES.CONSUMER} element={<ConsumerPage />} />
            <Route path={PagesRoutes.PAGES.CONSUMER_CREATE} element={<ConsumerEditPage className="editConsumer" />} />
            <Route path={PagesRoutes.PAGES.CONSUMER_UPDATE} element={<ConsumerEditPage className="editConsumer" />} />

            <Route path={PagesRoutes.PAGES.SUPPLIERS} element={<SuppliersPage />} />
            <Route path={PagesRoutes.PAGES.SUPPLIER} element={<SupplierPage />} />

            <Route path={PagesRoutes.PAGES.STATS} element={<StatsPage />} />
            <Route path={PagesRoutes.PAGES.INCIDENTS} element={<Incidents />} />
            <Route path={PagesRoutes.PAGES.INCIDENT} element={<IncidentPage />} />
            <Route path={PagesRoutes.PAGES.SPEECH_NOTIFICATIONS} element={<SpeechNotificationsPage />} />

            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" element={<Navigate to={PagesRoutes.PAGES.APPEALS} />} />
          </Routes>
        </GuardedRoute>
      </ErrorBoundary>
    </>
  );
};
